import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import Button from '../../../../helpers/button'
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import axiosFetch from "../../../../helpers/axios"
import { SET_ORDER_APPROVE, SET_ORDER_DECLINE } from '../../../../helpers/constants'

import "./styles.scss";

function CurrentOrders(props) {
  const { totalActiveOrdersItems } = props

  const [totalOrders, setTotalOrders] = useState([])

  useEffect(
    () => {
      setTotalOrders(totalActiveOrdersItems)
    },
    [totalActiveOrdersItems]
  );

  const renderOrderTables = (tablesHours) => {
    const content = tablesHours.map(tableLine => {
      const { tableNumber, startHour, startHourMin, endHour, endHourMin } = tableLine
      return (
        <div className="table-line">
          <span>
            Стол <b>№{tableNumber}</b>
          </span>
          <span>
            с <b>{startHour}: {Number(startHourMin) === 0 ? `${startHourMin}0` : startHourMin}</b> до <b>{endHour}: {Number(endHourMin) === 0 ? `${endHourMin}0` : endHourMin}</b>
          </span>
        </div>
      )
    })

    return content

  }

  const setApprove = (reserveId, index) => {
    const formData = new FormData()
    formData.append("reserveId", reserveId)
    axiosFetch(SET_ORDER_APPROVE, formData)

    const newArray = [
      ...totalOrders.slice(0, index),
      ...totalOrders.slice(index + 1)
    ];
    //item.push(...selectedHours)
    setTotalOrders(newArray)
  }

  const setDecline = (reserveId, index) => {
    const formData = new FormData()
    formData.append("reserveId", reserveId)
    axiosFetch(SET_ORDER_DECLINE, formData)

    const newArray = [
      ...totalOrders.slice(0, index),
      ...totalOrders.slice(index + 1)
    ];
    //item.push(...selectedHours)
    setTotalOrders(newArray)
  }

  const renderTableOrders = (orders, showButtons) => {
    const content = orders.map((orderLine, index) => {
      const { phoneNumber, reserveDate, userName, tablesHours, reserveId } = orderLine
      return (
        <tr >
          <td>
            <b>{userName}</b>
          </td>
          <td>
            <b>{reserveDate}</b>
          </td>
          <td>
            <b>{phoneNumber}</b>
          </td>
          <td>
            {renderOrderTables(tablesHours)}
          </td>
          {showButtons && <td>
            <Button
              type="button"
              className="btn-upper btn btn-primary "
              text={"Подтвердить"}
              onClick={() => setApprove(reserveId, index)}
            />
            <Button
              type="button"
              className="btn-upper btn btn-danger "
              text={"Отклонить"}
              onClick={() => setDecline(reserveId, index)}
            />
          </td>}
        </tr>
      )
    })

    return content
  }


  return (
    <div>
      <h2>Таблица бронирования</h2>
      <table>
        <thead>
          <th>Имя, фамилия</th>
          <th>Время заказа</th>
          <th>Телефон</th>
          <th>Столы</th>
          <th>Действия</th>
        </thead>
        <tbody>
          {renderTableOrders(totalOrders, true)}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(CurrentOrders)
);
