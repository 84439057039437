import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { LATETS_NEWS, WIDGET_NEWS_LIMIT_ITEMS } from "../../../helpers/constants";
import axiosFetch from "../../../helpers/axios";

import "./styles.scss";

function LatestNews() {

  const [latestNews, setLatestNews] = useState([])

  useEffect(() => {
    fetchLatestNews()
  }, [])


  const fetchLatestNews = () => {

    const formData = new FormData()
    formData.append("limit", WIDGET_NEWS_LIMIT_ITEMS)
    const response = axiosFetch(LATETS_NEWS, formData);

    Promise.resolve(response).then((resArr) => {
      setLatestNews(resArr);
    });
  }

  const renderNews = () => {
    const content = latestNews.map((item) => {
      const { descriptionRu, id, titleRu } = item

      return (

        <div className="right-content">
          <p className="news-title-right">{titleRu}</p>
          <p
            className="txt-right"
            dangerouslySetInnerHTML={{
              __html: descriptionRu
            }} />

          <Link to={`/news/${id}`} className="ca-more">
            <i className="fa fa-angle-double-right"></i>
            Читать далее...
          </Link>

        </div>

      )
    })

    return content
  }


  console.log("latestNews ->> ", latestNews)
  return (
    <div className="col-md-3 right-column latest-news">
      <div className="top-score-title col-md-12 right-title">
        <h3>Последние новости</h3>

        {renderNews()}

      </div>
    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(LatestNews)
);
