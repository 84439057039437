import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import Switch from '@material-ui/core/Switch';
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Input from '../../../../helpers/input'
import Button from '../../../../helpers/button'
import axiosFetch from "../../../../helpers/axios"
import { GET_ALL_TABLES, ADD_NEW_TABLE, CHANGE_TABLE_ACTIVITY } from '../../../../helpers/constants'

import "./styles.scss";

function ActiveTables(props) {

  const [tableNumber, setTableNumber] = useState("")
  const [totalTables, setTotalTables] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getAllTables = () => {
    const response = axiosFetch(GET_ALL_TABLES)
    Promise.resolve(response).then((response) => {
      setTotalTables(response)
    })
  }

  useEffect(
    () => {
      getAllTables()
    }, [tableNumber]);



  const addTable = () => {
    const formData = new FormData()
    formData.append("tableNumber", tableNumber)

    axiosFetch(ADD_NEW_TABLE, formData)
    setIsLoading(false)
    setTableNumber("")
    setTimeout(() => {
      getAllTables()
    }, 1000);
  }

  const renderAddTableForm = () => {
    return (
      <div className="add-table-container">
        <Input
          type="numer"
          id="table"
          value={tableNumber}
          placeholder="Номер стола"
          disabled={isLoading}
          className="form-control unicase-form-control text-input"
          onChange={(e) => setTableNumber(e.target.value)}
        />
        <Button
          type="button"
          className="btn-upper btn btn-primary reserve"
          text={"Добавить"}
          disabled={!tableNumber}
          onClick={() => addTable()}
        />
      </div>
    )
  }

  const changeTableActivity = (tableId, activity) => {
    const formData = new FormData()
    formData.append("tableId", tableId)
    formData.append("activity", activity ? 1 : 0)
    axiosFetch(CHANGE_TABLE_ACTIVITY, formData).then(() => {
      setTimeout(() => {
        getAllTables()
        
      }, 500);
    })
   
  }

  const renderTables = () => {
    const content = totalTables.map((tableLine, index) => {
      const { tableNumber, online, id } = tableLine
      return (
        <tr key={index}>
          <td>
            <b>{tableNumber}</b>
          </td>
          <td>
            <Switch
              checked={online > 0}
              onChange={(e) => changeTableActivity(id, e.target.checked)}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />


          </td>

        </tr>
      )
    })

    return content
  }

  return (
    <div >
      {renderAddTableForm()}
      <div>
        <h2>Столы</h2>
        <table>
          <thead>
            <th>Номер стола</th>
            <th>Online</th>
          </thead>
          <tbody>
            {renderTables()}
          </tbody>
        </table>
      </div>

    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(ActiveTables)
);
