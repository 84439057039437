//import { USD_VALUE, EURO_VALUE } from "../../constants"

export const isValidEmail = (email) => {
    const regExp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
}

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

export const setToken = () => {
  let token = "";
  let possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

  for (let i = 0; i < 50; i++)
      token += possible.charAt(Math.floor(Math.random() * possible.length));

  return token;
}


export const scrollToElement = elem => {
  const element = document.getElementById(elem)
  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = element.getBoundingClientRect()
  const needScrollToMyDivInPX = elemRect.top - bodyRect.top

  window.scrollTo({
    top: needScrollToMyDivInPX,
    behavior: 'smooth',
  })
}

/*export const showCurrencyPrice = (finalPrice, inCurrency) => {
  let price
  switch (inCurrency) {
    case "EURO":
      price = (finalPrice / EURO_VALUE).toFixed(2)
      break;
    case "USD":
      price = (finalPrice / USD_VALUE).toFixed(2)
      break;

    default:
      price = finalPrice
      break;
  }
  return price
}*/