import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Pagination from '../../../../../helpers/pagination'
import { GET_ALL_EVENTS, ITEMS_ON_THE_PAGE } from "../../../../../helpers/constants";
import axiosFetch from "../../../../../helpers/axios";

import "./styles.scss"

function EventsList() {

    const [eventsList, setEventsList] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        fetchEventsData()
    }, [])

    const fetchEventsData = (page) => {
        const formData = new FormData()
        formData.append("currentPage", page)
        formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)

        const response = axiosFetch(GET_ALL_EVENTS, formData);

        Promise.resolve(response).then((resArr) => {
            setTotalCount(resArr[0].total)
            setEventsList(resArr);
            //setIsLoading(false);
        });
    }

    const paginationClick = page => {
        //setIsLoading(true)
        setCurrentPage(page)
        fetchEventsData(page)
    }

    const renderEvents = () => {
        const content = eventsList.map((item) => {
            const { descriptionRu, id, image, titleRu, date } = item

            return (
                <div className="col-xs-6 col-md-6 one-new">

                    <div className="col-md-4 slide-cont-img">
                        <Link to={`/events/${id}`}>

                            <img className="scale_image" src={image} alt="" />
                            {/* <i className="fa fa-video-camera"></i> */}
                        </Link>
                    </div>
                    <div className="event_date dd-date">
                        {date}
                        {/* <div className="post_theme">Exlusive</div> */}
                    </div>
                    <Link to={`/events/${id}`}>
                        <h4>{titleRu}</h4>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: descriptionRu
                            }} />
                    </Link>
                </div>

            )
        })

        return content
    }

    return (
        <>
            <div className="row top-slide-info home-page-news-container">
                {renderEvents()}


            </div>
            {totalCount > ITEMS_ON_THE_PAGE && <Pagination
                onClick={paginationClick}
                total={totalCount}
                pageIndex={currentPage}
                pageSize={ITEMS_ON_THE_PAGE}
            />}
        </>
    );
}

const mapStateToProps = (state) => ({
});

export default withRouter(compose(connect(mapStateToProps, {
}))(EventsList));
