import React, { useState } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Button from "../../../../helpers/button";
import classnames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

function OneTableReserve(props) {
  const {
    reserveLine: { tableNumber, hours, userId },
    reserveMinutes,
    setCountReserve,
    deleteTableFromOrder
  } = props;
  const [minutes, setMinutes] = useState({ min: 0, max: 0 });
  const [isReserved, setIsReserved] = useState(false)
  const [isDeleteTable, setIsDeleteTable] = useState(false)
  const startHour = hours[0];
  const endHour = hours[hours.length - 1];

  const setTableMinutes = (param, value) => {
    const data = {
      ...minutes,
      [param]: Number(value),
    };
    setMinutes(data);
  };

  const setReserved = () => {
    //console.log("tableNumber, startHour, min, endHour, max ->>> " , tableNumber, startHour, min, endHour, max)
    reserveMinutes({ tableNumber, startHour, min, endHour, max, userId })
    setIsReserved(!isReserved)
    setCountReserve(true) // true - увеличение на 1
  }

  const canceledReserve = () => {
    setIsReserved(!isReserved)
    setCountReserve(false, tableNumber) // true - уменьшение на 1
  }

  const deleteTable = (e) => {
    deleteTableFromOrder(tableNumber)
    setIsDeleteTable(true)
  }

  const renderDeleteContainer = () => {
    return (
      <div className="delete-container">
        <h2>Удаляем?</h2>
        <div className="delete-button-container">
          <Button
            type="button"
            className="btn-upper btn btn-primary reserve"
            text={"Удалить"}
            onClick={() => deleteTable()}
          />
          <Button
            type="button"
            className="btn-upper btn btn-primary reserve"
            text={"Отменить"}
            onClick={() => setIsDeleteTable(false)}
          />
        </div>

      </div>
    )
  }
  
  const { min, max } = minutes;

  const hourGame = max >= min ? endHour - startHour  : endHour - startHour - 1
  const minuteGame = min > max ? 60 - min + max : min === max ? "00" : max - min

  return (
    <div className={classnames(isReserved && "table-ready", "reserve-line")} >
      {isDeleteTable && renderDeleteContainer()}
      {isReserved && <CheckCircleIcon className="done-icon" />}
      {!isReserved && <CloseIcon onClick={(e) => deleteTable(tableNumber, e)} className="close-icon" />}
      <h2>
        Стол <b>№{tableNumber}</b>
      </h2>
      <span>
        с <b>{startHour}: {min === 0 ? `${min}0` : min}</b>
      </span>
      <span>
        <input
          type="range"
          onChange={(e) => setTableMinutes("min", e.target.value)}
          value={min}
          disabled={isReserved}
          min="00"
          max="45"
          step="15"
        />
      </span>
      <span>
        до <b>{endHour}: {max === 0 ? `${max}0` : max}</b>
      </span>
      <span>
        <input
          type="range"
          onChange={(e) => setTableMinutes("max", e.target.value)}
          value={max}
          disabled={isReserved}
          min="00"
          max="45"
          step="15"
        />
      </span>
      <div className="game-time">
        Время игры: <b>{hourGame}ч. {minuteGame}мин.</b>
      </div>
      <Button
        type="button"
        className="btn-upper btn btn-primary reserve"
        text={!isReserved ? "Забронировать" : "Отменить"}
        //disabled={isReserved}
        onClick={() => !isReserved ? setReserved() : canceledReserve()}
      />
     
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default withRouter(
  compose(connect(mapStateToProps, {}))(OneTableReserve)
);
