import {
    GET_TOTAL_ORDERS,
    GET_ALL_ACTIVE_ORDERS,
    GET_ALL_NOT_ACTIVE_ORDERS,
    TOGGLE_SOUND_OFF,
    TOGGLE_SOUND_ON
} from "./Actions";
import { GET_NEW_ORDERS, GET_TOTAL_PAST_ORDERS } from '../helpers/constants'
import axiosFetch from "../helpers/axios"


export function getTotalOrders(counter, totalOrders, soundState) {

    return dispatch => {

        const payload = {
            counter,
            soundState: (counter > totalOrders) && soundState
        }

        if (counter > totalOrders) {
            getAllActiveOrders(dispatch);
        }

        dispatch({
            type: GET_TOTAL_ORDERS,
            payload
        })



    }
}

export function toggleNotificationSound(state) {

    return dispatch => {
        dispatch({
            type: !state ? TOGGLE_SOUND_OFF : TOGGLE_SOUND_ON,
        })
    }
}
export function getAllActiveOrders(dispatch) {

    const response = axiosFetch(GET_NEW_ORDERS)
    Promise.resolve(response).then((totalItems) => {
        dispatch({
            type: GET_ALL_ACTIVE_ORDERS,
            payload: totalItems
        })
    })
}

export function getAllPastOrders() {
    return dispatch => {
        const response = axiosFetch(GET_TOTAL_PAST_ORDERS)
        Promise.resolve(response).then((totalPastOrders) => {
            dispatch({
                type: GET_ALL_NOT_ACTIVE_ORDERS,
                payload: totalPastOrders
            })
        })
    }

}
