import React from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import "./styles.scss";

function PlayerSkills() {


  return (
    <div className="col-md-12 atp-single-player skill-content">
      <div className="exp-skill">
        <p className="exp-title-pp">SKILLS</p>
        <div className="skills-pp">
          <div
            className="skillbar-pp clearfix"
            data-percent="95%"
          >
            <div className="skillbar-title-pp">
              <span>Grip </span>
            </div>
            <div className="skillbar-bar-pp"></div>
            <div className="skill-bar-percent-pp">95%</div>
          </div>
          <div
            className="skillbar-pp clearfix"
            data-percent="84%"
          >
            <div className="skillbar-title-pp">
              <span>Serve </span>
            </div>
            <div className="skillbar-bar-pp"></div>
            <div className="skill-bar-percent-pp">84%</div>
          </div>
          <div
            className="skillbar-pp clearfix"
            data-percent="65%"
          >
            <div className="skillbar-title-pp">
              <span>Forehand</span>
            </div>
            <div className="skillbar-bar-pp"></div>
            <div className="skill-bar-percent-pp">65%</div>
          </div>
          <div
            className="skillbar-pp clearfix"
            data-percent="75%"
          >
            <div className="skillbar-title-pp">
              <span>Backhand</span>
            </div>
            <div className="skillbar-bar-pp"></div>
            <div className="skill-bar-percent-pp">75%</div>
          </div>
        </div>
      </div>
    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(PlayerSkills)
);
