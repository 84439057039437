import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Input from '../../../../helpers/input'
import Button from '../../../../helpers/button'
import Spinner from '../../../../helpers/spinner'
import axiosFetch from "../../../../helpers/axios"
import { GET_HOURS, SET_HOURS } from '../../../../helpers/constants'

import "./styles.scss";

function ActiveTables() {

  const [fromHour, setFromHour] = useState(0)
  const [toHour, setToHour] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

  useEffect(
    () => {
      getHours()
    }, []);

  const getHours = () => {
    const response = axiosFetch(GET_HOURS)
    Promise.resolve(response).then((response) => {
      const { fromHour, toHour } = response[0]
      setToHour(Number(toHour))
      setFromHour(Number(fromHour))
    })
  }


  console.log("toHour , fromHour", toHour, fromHour)




  const setHours = () => {
    setIsLoading(true)
    if (toHour <= fromHour) {
      setError("Некорректное время")
      return
    }
    const formData = new FormData()
    formData.append("fromHour", fromHour)
    formData.append("toHour", toHour)

    axiosFetch(SET_HOURS, formData)


    setTimeout(() => {
      setIsLoading(false)
      getHours()
    }, 1000);
  }



  return (
    <div >
      <div >
        <h2>Часы работы:</h2>
        <div style={{ display: "flex" }}>
          <div>
            <label>C</label>
            <Input
              type="numer"
              id="table"
              maxLength={2}
              value={fromHour}
              placeholder="С какого часа"
              disabled={isLoading}
              className="form-control unicase-form-control text-input"
              onChange={(e) => { setError(""); setFromHour(Number(e.target.value)) }}
            />
          </div>
          <div>
            <label>До</label>
            <Input
              type="numer"
              id="table"
              maxLength={2}
              value={toHour}
              placeholder="До какого часа"
              disabled={isLoading}
              className="form-control unicase-form-control text-input"
              onChange={(e) => { setError(""); setToHour(Number(e.target.value)) }}
            />

          </div>
          <Button
            type="button"
            className="btn-upper btn btn-primary change-hours"
            text={isLoading ? <Spinner /> : "Редактировать"}
            disabled={!fromHour || !toHour || isLoading}
            onClick={() => setHours()}
          />
          {error && <div className="error-box">{error}</div>}
        </div>


      </div>


    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(ActiveTables)
);
