import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import Header from '../../header'
import Footer from '../../footer'
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import moment from 'moment'
import AlertBanner from "../../../helpers/alert-banner";
import Input from '../../../helpers/input'
import { compose } from "redux";
import { withRouter } from "react-router-dom";
//import { fromHour, toHour } from "../../../helpers/constants/data-array"
import { SET_ORDER_TO_RESERVE, SET_TABLES_INTO_ORDER, GET_ALL_ORDERS, GET_ALL_TABLES, GET_HOURS } from "../../../helpers/constants"
import { scrollToElement } from "../../../helpers/constants/functions"
import axiosFetch from "../../../helpers/axios"
import Button from '../../../helpers/button'
import classnames from 'classnames'
import OneTableReserve from "./one-table-reserve"
import Spinner from "../../../helpers/spinner"
import InputMask from 'react-input-mask';

import "./styles.scss";

function Reserve(props) {

  const { loginData } = props

  const [selectedHours, setSelectedHours] = useState([])
  const [orderData, setOrderData] = useState({ name: "", phone: "" })
  const [tableMinutes, setTableMinutes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [countReserve, setCount] = useState(0)
  const [reloadPage, setReloadPage] = useState(false)
  const [totalTables, setTotalTables] = useState([])
  const [isBlocking, setBlockingAllTables] = useState(true)
  const [totalOrders, setTotalOrders] = useState([])

  const [fromHour, setFromHour] = useState(0)
  const [toHour, setToHour] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setBlockingAllTables(false)
      setIsLoading(false)

      const response = axiosFetch(GET_HOURS)
      Promise.resolve(response).then((response) => {
        const { fromHour, toHour } = response[0]
        setToHour(Number(toHour))
        setFromHour(Number(fromHour))
      })

    }, 3000);
    // if (!startInterval) return;
    const interval = setInterval(_ => {

      const response = axiosFetch(GET_ALL_ORDERS)
      Promise.resolve(response).then((response) => {
        setTotalOrders(response)
      })
      const allTables = axiosFetch(GET_ALL_TABLES)
      Promise.resolve(allTables).then((allTables) => {
        setTotalTables(allTables)
      })
    }, 3000);
    return _ => clearInterval(interval);
  }, [loginData, totalTables]);


  const onlineColor = "#499c0a"
  const offlineColor = "#d80d0d"
  const userId = Number(loginData.userId) || 0

  const setValue = (param, value) => {
    const data = {
      ...orderData,
      [param]: value
    }
    setOrderData(data)
  }
  const reserveMinutes = (valuesArr) => {

    const data = [{ ...valuesArr }];

    const newArray = [
      ...tableMinutes,
      ...data
    ];
    setTableMinutes(newArray);
  };


  const selectHours = (idSelected, numberSelected, hourSelected) => {
    //console.log("key, value", idSelected, numberSelected, hourSelected)
    const item = [
      { id: idSelected, number: numberSelected, hour: hourSelected, userId }
    ]

    let isHour

    selectedHours.forEach((oneHour, index) => {
      const { id, number, hour } = oneHour
      if (id === idSelected && number === numberSelected && hour === hourSelected) {
        isHour = index
      }
    })
    if (isHour > -1) {
      const newArray = [
        ...selectedHours.slice(isHour + 1),
        ...selectedHours.slice(0, isHour)
      ];
      //item.push(...selectedHours)
      setSelectedHours(newArray)
    } else {
      item.push(...selectedHours)
      setSelectedHours(item)
    }

  }

  const renderOfflineOverlay = () => {
    return (
      <div className="offline-table">
        Стол недоступен
      </div>
    )
  }

  const renderTablesList = () => {
    const content = totalTables.map((table) => {
      const { id, tableNumber, online } = table
      const background = online > 0 ? onlineColor : offlineColor
      return (
        <div className="table-line col-md-2" key={id}>
          {Number(online) === 0 && renderOfflineOverlay()}
          <div className="table-number">
            #{tableNumber}
          </div>
          <span>Доступное время:</span>
          <div className="online-table" style={{ background }} />
          {renderHoursList(id, tableNumber, online)}
          <div className="clear" />
        </div>
      )
    })

    return content
  }

  const checkIfIsChecked = (idTable, numberTable, hourTable) => {
    let checked
    selectedHours.forEach(({ id, number, hour }) => {
      //id === idTable && 
      if (number === numberTable && hour === hourTable) {
        checked = true
      }
    })
    return checked

  }

  const checkFillInHoursCell = (startHour, startHourMin, endHour, endHourMin, i, tableNumber) => {

    const getTheStartHourMin = totalOrders.find(line => Number(line.tableNumber) === tableNumber && Number(line.startHour) === i)
    const getTheEndHourMin = totalOrders.find(line => Number(line.tableNumber) === tableNumber && Number(line.endHour) === i)

    let fill

    if (getTheEndHourMin && getTheStartHourMin) {
      if (getTheEndHourMin.endHourMin === getTheStartHourMin.startHourMin) {
        fill = "fill-items-full"
      } else {
        if (Number(getTheEndHourMin.endHourMin) === 15 && Number(getTheStartHourMin.startHourMin) === 45) {
          fill = "fill-only-1 fill-only-4"
        } else if (Number(getTheEndHourMin.endHourMin) === 15 && Number(getTheStartHourMin.startHourMin) === 30) {
          fill = "fill-only-1 fill-only-3 fill-only-4"
        }
        else if (Number(getTheEndHourMin.endHourMin) === 30 && Number(getTheStartHourMin.startHourMin) === 45) {
          fill = "fill-only-1 fill-only-2 fill-only-4"
        }
      }
    } else {
      if (i === Number(startHour)) {


        switch (Number(startHourMin)) {
          case 0:
            fill = "fill-items-full"
            break;

          case 15:
            fill = "fill-items-end-3"
            break;
          case 30:
            fill = "fill-items-end-2"
            break;

          default: //45
            fill = "fill-items-end-1"
            break;
        }
      } else {
        switch (Number(endHourMin)) {
          case 0:
            //fill = "fill-items-full3"
            break;

          case 15:
            fill = "fill-items-start-1"
            break;
          case 30:
            fill = "fill-items-start-2"
            break;

          default: //45
            fill = "fill-items-start-3"
            break;
        }
      }
    }




    return fill
  }

  const renderReserveMinutes = (occupatedHour, i, tableNumber) => {
    const { startHour, startHourMin, endHour, endHourMin } = occupatedHour

    const style = "fill-items-full"

    const fill = Number(endHour) > i && i > Number(startHour) ? style : checkFillInHoursCell(startHour, startHourMin, endHour, endHourMin, i, tableNumber)

    return (
      <div className={classnames("fill-minutes-container table-hour col-md-2", fill && `${fill}`)} >
        <div className={"show-minute"} />
        <div className={"show-minute"} />
        <div className={"show-minute"} />
        <div className={"show-minute"} />
      </div>
    )
  }

  const renderHoursList = (id, number, online) => {
    const items = []
    for (let i = fromHour; i <= toHour; i++) {
      const isChecked = checkIfIsChecked(id, number, i)

      const occupatedTime = totalOrders.find((reserveLine => Number(reserveLine.tableNumber) === Number(number) && Number(reserveLine.startHour) <= i && Number(reserveLine.endHour) >= i))
      const canClickOnHourCell = totalOrders.find((reserveLine => Number(reserveLine.tableNumber) === Number(number) && Number(reserveLine.startHour) < i && Number(reserveLine.endHour) > i))

      items.push(
        <div
          onClick={() => !canClickOnHourCell && online && selectHours(id, number, i)}
          className={classnames(isChecked && "checked", "table-hour col-md-2 hour-container",)}
        >
          {occupatedTime && renderReserveMinutes(occupatedTime, i, Number(number))}
          <div className="hour-label">{i}</div>
        </div>
      )

    }
    return items

  }


  const reserveTable = () => {
    scrollToElement("reserveTable")
    setBlockingAllTables(true)
    setIsLoading(true)
    const { userId } = loginData

    const insertedPhone = phone ? phone : loginData.phone
    const insertedName = name ? name : loginData.name

    const formData = new FormData()
    formData.append("userId", userId || 0)
    formData.append("name", insertedName)
    formData.append("phone", insertedPhone)
    formData.append("date", moment().format("DD/MM/YYYY HH:mm"))

    const orderId = axiosFetch(SET_ORDER_TO_RESERVE, formData)
    promiseResolved(orderId)


  }

  const promiseResolved = (response) => {
    Promise.resolve(response).then((orderId) => {

      tableMinutes.forEach(oneOrder => {
        const { endHour, max, min, startHour, tableNumber } = oneOrder

        const formData = new FormData()

        formData.append("endHour", endHour)
        formData.append("max", max)
        formData.append("min", min)
        formData.append("startHour", startHour)
        formData.append("tableNumber", tableNumber)
        formData.append("orderId", orderId)
        axiosFetch(SET_TABLES_INTO_ORDER, formData)
      })
    })
    setTimeout(() => {
      setIsLoading(false)
      setReloadPage(true)
      setBlockingAllTables(false)
    }, 1500);
  }


  const showReserveForm = () => {

    const arrTableHours = []

    totalTables.forEach(oneTable => {
      const hours = []
      const { tableNumber } = oneTable

      selectedHours.forEach(oneHour => {

        const { number, hour } = oneHour
        if (tableNumber === number) {
          hours.push(hour)
        }

      })
      arrTableHours.push({ tableNumber, hours, userId })
    })
    return arrTableHours
  }


  const setCountReserve = (action, number) => {
    if (action) {
      setCount(countReserve + 1)

    } else {
      setCount(countReserve - 1)
      const finishArrr = tableMinutes.filter((el) => el.tableNumber !== number);
      setTableMinutes(finishArrr);
    }

  }

  const deleteTableFromOrder = (tableNumber) => {

    const finishArrr = selectedHours.filter(line => line.number !== tableNumber)
    setSelectedHours(finishArrr)

  }

  const showReserve = () => {
    const filteredHours = showReserveForm()

    const content = filteredHours.map(reserveLine => {
      const { hours } = reserveLine
      if (hours.length > 1) {

        hours.sort((a, b) => (a - b))

        return <OneTableReserve
          {...{ reserveLine }}
          {...{ reserveMinutes }}
          {...{ setCountReserve }}
          {...{ deleteTableFromOrder }}
        />
      }
      return true
    })

    return content
  }


  const renderReserveActionButton = () => {
    return (
      <div className="reserve-action-button" onClick={() => scrollToElement("scrollToReserveBlock")}>
        Забронировать {`(${countReserve}/${countTablesNumbers})`}
      </div>
    )
  }

  let tablesArr = []

  selectedHours.forEach(({ number }) => {
    tablesArr.push(number)
  })

  const uniqueTags = [];
  tablesArr.map(img => {
    if (uniqueTags.indexOf(img) === -1) {
      uniqueTags.push(img)
    }
    return true
  });

  const countTablesNumbers = uniqueTags.length;

  const { name, phone } = orderData

  const reserveButtonLabel = countTablesNumbers !== countReserve ? `Подтвердите время (${countReserve}/${countTablesNumbers})` : "Забронировать"

  const isValidField = !loginData.userId ? name.length === 0 || phone.length === 0 : false
  const disabled = countTablesNumbers !== countReserve

  return (


    <div>
      {countTablesNumbers > 0 && renderReserveActionButton(reserveButtonLabel)}
      {reloadPage && (
        <AlertBanner
          type={"success"}
          fontAwesomeType={"fa-check"}
          alertFirstText={"Страница перезагрузится через"}
          title={"Ваш заказ принят. С вами свяжется оператор. Спасибо"}
          timer={4}
          urlRedirect={"reserve"}
        />
      )}
      <Header />
      <div className="container secondary-page reserve-page">

        <div className="general">

          <div className="col-md-12" id="reserveTable">
            <h2>Резервация столов
              <div className="reserve-date">
                {moment().format("DD-MM-YYYY")}
              </div>
            </h2>
            {isLoading ? (
              <Spinner />
            ) : (<div className="tables-container">

              {isBlocking && <div className="blocking-overlay" />}
              {renderTablesList()}
              <div className="clear" />
            </div>)}
            {!isLoading &&
              <div className="reserve-form-container-parent">
                <div className="reserve-form-container">
                  <div id="scrollToReserveBlock" />
                  {showReserve()}
                </div>
                {selectedHours.length > 1 && (
                  <div className="order-container">
                    <Input
                      type="text"
                      id="name"
                      value={name ? name : loginData.name}
                      disabled={disabled || loginData.name}
                      placeholder="Имя, фамилия"
                      className="form-control unicase-form-control text-input"
                      onChange={(e) => setValue("name", e.target.value)}
                    />
                    {/* <Input
                      type="text"
                      id="email"
                      value={phone ? phone : loginData.phone}
                      placeholder="Телефон"
                      disabled={disabled || loginData.phone}
                      className="form-control unicase-form-control text-input"
                      onChange={(e) => setValue("phone", e.target.value)}
                    /> */}

                    <InputMask
                      mask="\0 99 99 99 99"
                      maskChar=" "
                      type="text"
                      id="email"
                      value={phone ? phone : loginData.phone}
                      placeholder="Телефон"
                      disabled={disabled || loginData.phone}
                      className="form-control unicase-form-control text-input"
                      onChange={(e) => setValue("phone", e.target.value)}
                    />


                    <Button
                      type="button"
                      className="btn-upper btn btn-primary reserve"
                      text={reserveButtonLabel}
                      disabled={disabled || isValidField}
                      onClick={() => reserveTable()}
                    />
                  </div>
                )}
              </div>}
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}

const mapStateToProps = (state) => ({
  loginData: state.SetLoginReducer.loginData,
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(Reserve)
);
