export const HVAT_ARRAY = [
    {id: 1, value: "Левый" },
    {id: 2, value: "Правый" },
]

export const GENDER_ARRAY = [
    {id: 1, value: "Мужской" },
    {id: 2, value: "Женский" },
]

export const PLAYER_STATUS_ARRAY = [
    {id: 1, value: "Новичок" },
    {id: 2, value: "Любитель" },
    {id: 3, value: "Профессионал" },
]

export const ZAYAVKA_SELECT_ARRAY = [
    {id: 1, value: "Не назначено" },
    {id: 2, value: "Данные предоставлены" },
    {id: 3, value: "Нет данных" },
]

export const ZAYAVKA_STATUS_ARRAY = [
    {id: 1, value: "Состав" },
    {id: 2, value: "Дисквалифицирован" },
    {id: 3, value: "Дозаявлен" },
    {id: 4, value: "Отзаявлен" },
]


export const TABLES_ARRAY = [
    {id: 1, tableNumber: 1, online: true },
    {id: 2, tableNumber: 2, online: true },
    {id: 3, tableNumber: 3, online: true },
    {id: 4, tableNumber: 4, online: true },
    {id: 5, tableNumber: 5, online: true },
    {id: 6, tableNumber: 6, online: true },
    {id: 7, tableNumber: 7, online: true },
    {id: 8, tableNumber: 8, online: true },
    {id: 9, tableNumber: 9, online: true },
    {id: 10, tableNumber: 10, online: true },
]

export const START_WORK_TIME = 8 //hours
export const END_WORK_TIME = 22 //hours
