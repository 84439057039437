import {
    TOGGLE_SOUND_OFF,
    TOGGLE_SOUND_ON} from "../actions/Actions";

const initialState = {
    soundState: false,
    
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_SOUND_ON: {

            return Object.assign({}, {
                ...initialState,
                soundState: false,
            });
        }
        case TOGGLE_SOUND_OFF: {
            
            return Object.assign({}, {
                ...initialState,
                soundState: true,
            });
        }

        default:
            return state;
    }
}

