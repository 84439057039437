
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/';
import Store from "./store/Store";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
//import moment from "moment";

const history = createBrowserHistory();

//moment.locale(Store.getState().PrefsReducer.lng);

ReactDOM.render(
    <Provider store={Store}>
        <Router history={history} basename='/'>
            <SnackbarProvider maxSnack={3}>
                <App />
            </SnackbarProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);

