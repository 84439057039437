import React, { Component } from 'react';

import './select.scss';

export default class Select extends Component {  

    renderSelectItems = () => {
        const { items } = this.props;
        const menu = items.map((menuItem)=> {
            const { id, value, name } = menuItem;
            //const intId = parseInt(id);
            //const intSelectedItem = parseInt(selectedItem);
            //const selected = intSelectedItem === intId ? 'selected' : '';
            
            return(
                <option key={id} value={id} >
                    {value ? value: name}
                </option>
            );
        })
        return menu;
    }

    render(){
        const { 
            required = true, 
            colMd = '', 
            name = 'input', 
            defaultValue,
            disabled = false,
            onChange,
            className,
            selectedItem
        } = this.props;

        return(
            <div className={colMd}>
                <select 
                    className={className} 
                    onChange={onChange} 
                    name={name} 
                    required={required}
                    disabled={disabled}
                    //defaultValue={defaultValue}
                    value={selectedItem}
                >
                    <option value={0}>{defaultValue}</option>
                    {this.renderSelectItems()}
                </select>
            </div>
        );
    };
}