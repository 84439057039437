import React, { useState,  } from "react";

import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import Button from '../../../../helpers/button'
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import EventsTable from "./events-table"
import AddEditOneItem from "./add-edit-one-item"
import classNames from "classnames"


import "./styles.scss";

function AdminNews() {

  const [showStepForm, setShowStepForm] = useState("eventsList")
  
  const [editItemData, setEditItemData] = useState({})

  const goBack = (step = "eventsList") => {
    setShowStepForm(step)
    //fetchNewData()
  }

  const setEditEventsItem = (item) => {
    setEditItemData(item)
  }

  const showContent = () => {
    let content = null
    switch (showStepForm) {
      case "addItem":
        content = <AddEditOneItem {...{ goBack }} />
        break;
      case "editItem":
        content = <AddEditOneItem {...{ goBack }} {...{editItemData}} />
        break;

      default:
        content = <EventsTable  {...{ goBack }} {...{setEditEventsItem}} />
        break;
    }
    return content
  }


//{...{ eventsList }}
  return (
    <div className="adminpanel-news-comtainer">
      <div
        className={classNames({
          "buttons-container": true,
          "fl-con-fl-end": true,
        })}>

        {(showStepForm === "addItem" || showStepForm === "editItem") && <Button
          type="button"
          className="btn-upper btn "
          text={"Назад"}
          disabled={false}
          onClick={() => setShowStepForm("eventsList")}
        />
        }
        {showStepForm !== "addItem" && showStepForm !== "editItem" && <Button
          type="button"
          className="btn-upper btn btn-primary reserve"
          text={"Добавить мероприятие"}
          disabled={false}
          onClick={() => setShowStepForm("addItem")}
        />}


      </div>
      {showContent()}
      {/* <AddEditOneItem {...{goBack}}/>
      <EventsTable /> */}
    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(AdminNews)
);
