import React from "react";
//import { Link } from "react-router-dom";
import "./styles.scss";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { Link } from 'react-router-dom';
import { compose } from "redux";
import { withRouter } from "react-router-dom";

function Footer(props) {


  return (
    <div>
      <section id="footer-tag">
        <div className="container">
          <div className="col-md-12" style={{paddingBottom: 20}}>
            <div className="col-md-3" style={{ display: "none" }}>
              <h3>О нас</h3>
              <p>Академия настольного тенниса имени Софии Полкановой первая в Кишиневе спортивная школа, оборудованная по европейским стандартам. .</p>
              <p>Воплотить эту идею ей помогали общественная организация Top Cup, Олег Добржинский, Дмитрий Донсков и Александр Поличук.
                Они вместе организовали проект по ремонту спортзала, где теперь будет располагаться Академия.</p>
            </div>
            <div className="col-md-3 cat-footer" style={{ display: "none" }}>
              <div className="footer-map"></div>
              <h3 className='last-cat'>Меню</h3>
              <ul className="last-tips">
                <li>
                  <Link to="/rating">
                    Рейтинг
                  </Link>
                </li>
                <li>
                  <Link to="/tournaments">
                    Турниры
                  </Link>
                </li>
                <li>
                  <Link to="/players">
                    Игроки
                  </Link>
                </li>
                <li>
                  <Link to="/results">
                    Результаты
                  </Link>
                </li>
                <li>
                  <Link to="/news">
                    Новости
                  </Link>
                </li>
                <li>
                  <Link to="/shop">
                    Магазин
                  </Link>
                </li>
                <li>
                  <Link to="/training">
                    Тренировки
                  </Link>
                </li>
                <li>
                  <Link to="/contacts">
                    Контакты
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3" style={{ display: "none" }}>
              <h3>Last News</h3>
              <ul className="footer-last-news">
                <li><img src="http://placehold.it/320x213" alt="" /><p>Fusce risus metus, placerat in consectetur eu...</p></li>
                <li><img src="http://placehold.it/320x213" alt="" /><p>Fusce risus metus, placerat in consectetur eu...</p></li>
                <li><img src="http://placehold.it/320x213" alt="" /><p>Fusce risus metus, placerat in consectetur eu...</p></li>
              </ul>
            </div>
            <div className="col-md-3 footer-newsletters" style={{ display: "none" }}>
              <h3>Newsletters</h3>
              <form method="post">
                <div className="name">
                  <label htmlFor="name">* Name:</label><div className="clear"></div>
                  <input id="name" name="name" type="text" placeholder="e.g. Mr. John Doe" required="" />
                </div>
                <div className="email">
                  <label htmlFor="email">* Email:</label><div className="clear"></div>
                  <input id="email" name="email" type="text" placeholder="example@domain.com" required="" />
                </div>
                <div id="loader">
                  <input type="submit" value="Submit" />
                </div>
              </form>
            </div>
            <div className="col-xs-12" >
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/top.cup.md/">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="col-md-12 content-footer">
          <p>© 2021 topcup.md All rights reserved. </p>
        </div>
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(Footer)
);
