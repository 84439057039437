import React from "react";
import HeaderNavBar from "../header/header-nav-bar";
import HeaderTopBar from "../header/header-top-bar";

import "./styles.scss"

const Header = () => {
  return (
    <header className="header-style-1">
      <HeaderTopBar />
      <HeaderNavBar />
    </header>
  );
};
export default Header;
