import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import Header from '../../header'
import Footer from '../../footer'
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import Tab from "@material-ui/core/es/Tab/Tab";
import classNames from "classnames"
import Spinner from "../../../helpers/spinner";
import ActiveSettings from "./settings"
import ActiveTables from "./tables"
import CurrentOrders from "./current-orders"
import PastOrders from "./past-orders"

import "./styles.scss";

function ShowOrders(props) {

  const AllOrders = "AllOrders";
  const PastOrdersTab = "PastOrdersTab";
  const Tables = "Tables"
  const Settings = "Settings"

  const { totalActiveOrdersItems, totalPastOrderItems } = props
  const [activeTab, setActiveTab] = useState(AllOrders);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(
    () => {
      setTimeout(() => {
        setIsLoading(false)
      }, 3000);
    }, []);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const renderTabsContent = () => {
    return (
      <>
        <Tabs value={AllOrders} onChange={handleTabChange} className="tabs-container">
          <Tab
            value={AllOrders}
            label={"Текущие заказы"}
            className={classNames({
              selected: activeTab === AllOrders,
            })}
          />
          <Tab
            value={PastOrdersTab}
            label={"Прошлые заказы"}
            className={classNames({
              selected: activeTab === PastOrdersTab,
            })}
          />
          <Tab
            value={Tables}
            label={"Столы"}
            className={classNames({
              selected: activeTab === Tables,
            })}
          />
          <Tab
            value={Settings}
            label={"Часы работы"}
            className={classNames({
              selected: activeTab === Settings,
            })}
          />
        </Tabs>

        {activeTab === AllOrders && <CurrentOrders {...{totalActiveOrdersItems}}/>} 
        {activeTab === PastOrdersTab && <PastOrders {...{totalPastOrderItems}}/>}
        {activeTab === Tables && <ActiveTables />}
        {activeTab === Settings && <ActiveSettings />}
      </>
    )
  }

  const content = !isLoading ? renderTabsContent() : <Spinner />

  return (
    <div>
      <Header />
      <div className="container secondary-page orders-page">
        <div className="general">
          <div className="col-md-12">
            {content}
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}

const mapStateToProps = (state) => ({
  totalActiveOrdersItems: state.GetTotalOrdersReducer.totalActiveOrdersItems,
  totalPastOrderItems: state.GetTotalOrdersReducer.totalPastOrderItems
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(ShowOrders)
);
