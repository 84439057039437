import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Header from '../../header'
import Footer from '../../footer'
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import Tab from "@material-ui/core/es/Tab/Tab";
import AdminNews from "./admin-news"
import AdminEvents from "./admin-events";
import classNames from "classnames"


import "./styles.scss";

function Adminpanel(props) {
    const { loginData } = props
    const News = "News";
    const Events = "Events";

    const [activeTab, setActiveTab] = useState(News);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        console.log("loginData ->> ", loginData)
        if (!loginData.userRole) {
            setRedirect(true)
        }
    }, [loginData])

    





    const handleTabChange = (event, value) => {
        setActiveTab(value);
    };


    return (
        <div>
            {redirect && <Redirect to="/" />}
            <Header />
            <div className="container secondary-page adminpanle-page">
                <div className="general">
                    <div className="col-md-3">
                        <>
                            <Tabs value={News} onChange={handleTabChange} className="tabs-container">
                                <Tab
                                    value={News}
                                    label={"Новости / статьи"}
                                    className={classNames({
                                        selected: activeTab === News,
                                    })}
                                />
                                <Tab
                                    value={Events}
                                    label={"События / турниры"}
                                    className={classNames({
                                        selected: activeTab === Events,
                                    })}
                                />
                            </Tabs>
                        </>
                    </div>
                    <div className="col-md-9">
                        {activeTab === News && <AdminNews />}
                        {activeTab === Events && <AdminEvents />}
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}

const mapStateToProps = (state) => ({
    loginData: state.SetLoginReducer.loginData
});

export default withRouter(
    compose(connect(mapStateToProps, {}),)(Adminpanel)
);
