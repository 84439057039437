// token related
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const MENU_DATA_START = "MENU_DATA_START"
export const MENU_DATA_SUCCESS = "MENU_DATA_SUCCESS"
export const CURRENCY_VALUE = "CURRENCY_VALUE"
export const SEARCH_CATEGORY = "SEARCH_CATEGORY"
export const SET_LOGIN = "SET_LOGIN"
export const ADD_TO_CART_START = "ADD_TO_CART_START"
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS"
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL"

export const SET_TOKEN = "SET_TOKEN"
export const GET_CART_ITEMS_START = "GET_CART_ITEMS_START"
export const GLOBAL_SEARCH_TEXT = "GLOBAL_SEARCH_TEXT"
export const GLOBAL_SETTINGS = "GLOBAL_SETTINGS"
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"

export const GET_CONTACTS = "GET_CONTACTS"
export const SET_GLOBAL_SEARCH_RESULT = "SET_GLOBAL_SEARCH_RESULT"
export const HIDE_GLOBAL_SEARCH_CONTAINER = "HIDE_GLOBAL_SEARCH_CONTAINER"

export const GET_TOTAL_ORDERS = "GET_TOTAL_ORDERS"

export const TOGGLE_SOUND_OFF = "TOGGLE_SOUND_OFF"
export const TOGGLE_SOUND_ON = "TOGGLE_SOUND_ON"
export const GET_ALL_ACTIVE_ORDERS = "GET_ALL_ACTIVE_ORDERS"
export const GET_ALL_NOT_ACTIVE_ORDERS = "GET_ALL_NOT_ACTIVE_ORDERS"
