import React, { useState, useEffect, Fragment } from "react";
//import { Link } from "react-router-dom";
import Header from "../../header";
import Footer from "../../footer";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { GET_USER_INFO_BY_EMAIL, NO_IMAGE_URL, EDIT_USER_PROFILE } from "../../../helpers/constants";
import { HVAT_ARRAY, PLAYER_STATUS_ARRAY } from "../../../helpers/constants/data-array"
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import axiosFetch from "../../../helpers/axios";
import CookieHelper from "../../../helpers/cookie-helper";
import Spinner from "../../../helpers/spinner";
import Input from "../../../helpers/input";
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import LatestNews from "../../sidebar/latest-news"
import PlayerSkills from "../../sidebar/player-skills"
import Select from "../../../helpers/select";
import moment from "moment"

import "./styles.scss";

function MyCabinet(props) {
  // const {
  //   loginData,
  // } = props;

  const emptyData = {
    userPhone: "",
    birthday: "",
    rost: "",
    ves: "",
    hvat: "",
    userLevel: "",
    userPicture: "",
    userName: "",
  }

  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [updateData, setUpdateData] = useState(emptyData)
  const [photoPreview, setPhotoPreview] = useState()

  useEffect(() => {
    fetchUserData()
  }, [setUserInfo]);

  const fetchUserData = () => {
    const userEmail = CookieHelper("get", "userEmail");

    const formData = new FormData();
    formData.append("email", userEmail);

    const response = axiosFetch(GET_USER_INFO_BY_EMAIL, formData);
    Promise.resolve(response).then((resArr) => {
      setUserInfo(resArr[0]);
      setIsLoading(false);
    });
  }

  const {
    userEmail,
    userName,
    birthday,
    hvat,
    rating,
    rost,
    userId,
    userPhone,
    userPicture,
    userRole,
    userLevel,
    ves,
  } = userInfo;

  const setValue = (param, value) => {
    const data = {
      ...updateData,
      [param]: value
    }
    setUpdateData(data)
  }

  const handleChangePhoto = (file) => {
    const data = {
      ...updateData,
      userPicture: file
    }
    setUpdateData(data)
    setPhotoPreview(URL.createObjectURL(file))
  }

  const checkUserRole = (userRole) => {
    let role = ""

    switch (userRole) {
      case "user":
        role = "Пользователь"
        break;
      case "admin":
        role = "Администратор"
        break;

      default:
        role = "Редактор"
        break;
    }
    return role
  }

  const renderPlayerContent = () => {

    const finishImage = userPicture.length === 0 ? NO_IMAGE_URL : userPicture
    const imageSrc = photoPreview ? photoPreview : finishImage;

    const finalHvat = HVAT_ARRAY.find(el => el.id === Number(hvat))
    const finalUserLevel = PLAYER_STATUS_ARRAY.find(el => el.id === Number(userLevel))
    return (
      <Fragment>
        <div className="col-md-4 data-player">
          <div
            className="profile-img-container"
            style={{
              backgroundImage: `url(${imageSrc})`,
            }}
          />
          {isEditMode && <Input
            colMd={""}
            type={"file"}
            placeholder={"Фотография игрока"}
            name={"avatar"}
            onChange={(e) => handleChangePhoto(e.target.files[0])}
          />}
        </div>


        <div className="col-md-4 data-player">
          <p>Рейтинг:</p>
          <p>Email:</p>
          <p>Телефон</p>
          <p>День рождения:</p>
          <p>Рост:</p>
          <p>Вес:</p>
          <p>Участий в турнирах:</p>
          <p>Хват:</p>
          <p>Уровень:</p>
          <p>Роль на сайте:</p>
        </div>
        <div className="col-md-3 data-player-2">
          <p>{rating}</p>
          <p>{userEmail}</p>
          <p>
            {!isEditMode ? userPhone : (
              <Input
                value={updateData.userPhone}
                className={"form-control custom-input"}
                type={"text"}
                placeholder={"Телефон"}
                onChange={(e) => setValue("userPhone", e.target.value)}
              />
            )}
          </p>
          {/* <p>{userPhone}</p> */}
          <p>
            {!isEditMode ? moment(birthday).format("DD/MM/YYYY") : (
              <Input
                value={updateData.birthday}
                className={"form-control custom-input"}
                type={"date"}
                placeholder={"День рождения"}
                onChange={(e) => setValue("birthday", e.target.value)}
              />
            )}
          </p>


          <p>
            {!isEditMode ? rost : (
              <Input
                value={updateData.rost}
                className={"form-control custom-input"}
                type={"text"}
                placeholder={"Рост"}
                onChange={(e) => setValue("rost", e.target.value)}
              />
            )}
          </p>

          <p>
            {!isEditMode ? ves : (
              <Input
                value={updateData.ves}
                className={"form-control custom-input"}
                type={"text"}
                placeholder={"Вес"}
                onChange={(e) => setValue("ves", e.target.value)}
              />
            )}
          </p>
          <p>15</p>
          <p>
            {!isEditMode ? finalHvat ? finalHvat.value : "" : (
              <Select
                className={"form-control custom-input"}
                selectedItem={updateData.hvat}
                items={HVAT_ARRAY}
                onChange={(e) => setValue("hvat", e.target.value)}
              />
            )}
          </p>
          <p>
            {!isEditMode ? finalUserLevel ? finalUserLevel.value : "" : (
              <Select
                className={"form-control custom-input"}
                selectedItem={updateData.userLevel}
                items={PLAYER_STATUS_ARRAY}
                onChange={(e) => setValue("userLevel", e.target.value)}
              />
            )}
          </p>
          <p>{checkUserRole(userRole)}</p>
        </div>
      </Fragment>
    );
  };

  const saveProfile = () => {
    setIsLoading(true);
    const { userPhone, birthday, rost, ves, hvat, userLevel, userPicture, userName } = updateData

    const formData = new FormData()
    formData.append("userPhone", userPhone)
    formData.append("birthday", birthday)
    formData.append("rost", rost)
    formData.append("ves", ves)
    formData.append("hvat", hvat)
    formData.append("userLevel", userLevel)
    formData.append("userPicture", userPicture)
    formData.append("userName", userName)
    formData.append("userId", userId)

    axiosFetch(EDIT_USER_PROFILE, formData).then(() => {
      resetUpdatedData()
      setTimeout(() => {
        setIsLoading(false)
        fetchUserData()
      }, 500);
    })


  }

  const playerInfo = isLoading ? <Spinner /> : renderPlayerContent();

  const resetUpdatedData = () => {
    setIsEditMode(false)
    setUpdateData(emptyData)
  }

  const showEditStateFrom = () => {
    setIsEditMode(true)
    const data = {
      userPhone,
      birthday,
      rost,
      ves,
      hvat,
      userLevel,
      userPicture,
      userName
    }
    setUpdateData(data)
  }


  const renderActionIcons = () => {
    return (
      <div>
        {!isEditMode ? (
          <CreateIcon className="edit-profile" onClick={() => showEditStateFrom()} />
        ) : (
          <div className="edit-profile">
            {Object.keys(updateData).length > 0 && <SaveIcon onClick={() => saveProfile()} />}
            <CloseIcon onClick={() => resetUpdatedData()} />
          </div>
        )}

      </div>
    )
  }

  return (
    <div>
      <Header />
      <div className="container secondary-page">
        <div className="general">
          <div className="col-md-12">
            <section id="single_player" className="container secondary-page">
              <div className="general general-results players">
                <div className="top-score-title right-score col-md-9">
                  <h3>
                    <span>
                      {!isEditMode ? userName : (
                        <Input
                          value={updateData.userName}
                          className={"form-control custom-input user-name"}
                          type={"text"}
                          placeholder={"Имя, фамилия"}
                          onChange={(e) => setValue("userName", e.target.value)}
                        />
                      )}
                    </span>
                  </h3>
                  <div className="col-md-12 atp-single-player">
                    {renderActionIcons()}
                    {playerInfo}
                  </div>

                  <PlayerSkills />

                </div>

                <LatestNews />
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginData: state.SetLoginReducer.loginData,
});

export default withRouter(compose(connect(mapStateToProps, {}))(MyCabinet));
