import React, { Component } from "react";
import Header from '../../header'
import Footer from '../../footer'
//import Slider from './slider'
import GetStartedToday from './get-started-today'
import LastTournaments from './last-tournaments'
import LastEvents from './last-events'
//import LastTournamentsTables from "./last-tournaments-tables"
// import NextMatch from "./next-match"
// import LatestNews from "./lastest-news"
// import Statistics from "./statistics"
// import PhotoGallery from "./photo-gallery"
// import Testimonials from "./testimonials"
// import TopProducts from "./top-products"

import "./styles.scss"

export default class HomePage extends Component {
  render() {
    return (
      <div className="cnt-home">
        <Header />
        {/* <Slider /> */}
        <GetStartedToday />
        {/* <LastTournamentsTables /> */}
        <LastEvents />
        <LastTournaments />
        
       {/*  <NextMatch />
        <LatestNews />
        <Statistics />
        <PhotoGallery />
        <Testimonials />
        <TopProducts /> */}
        <Footer />
      </div>
    );
  }
}
