import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import Header from '../../header'
import Footer from '../../footer'
import { GET_ONE_NEWS_DETAILS } from "../../../helpers/constants";
import axiosFetch from "../../../helpers/axios";
import LatestNews from "../../sidebar/latest-news"

import "./styles.scss"

function OneNewsDetails() {
    const { id } = useParams()
    const [oneNewData, setOneNewData] = useState([])


    useEffect(() => {
        const fetchNewData = () => {

            const formData = new FormData()
            formData.append("id", id)
            const response = axiosFetch(GET_ONE_NEWS_DETAILS, formData);

            Promise.resolve(response).then((resArr) => {
                setOneNewData(resArr[0]);
            });
        }

        fetchNewData()
    }, [id])




    const { descriptionRu, image, titleRu, date, videoUrl } = oneNewData
    return (
        <div >
            <Header />


            {/******************************************************************** */}

            <section className="drawer">
                <div className="col-md-12 size-img back-img-shop" style={{ backgroundImage: `url(${image})`, backgroundPosition: "center" }}>
                    <div className="effect-cover">

                    </div>
                </div>

                <section id="single_news" className="container news-details">
                    <div className="general general-results">
                        <div className="top-score-title col-md-9">
                            <h1>{titleRu}<span className="point-little">.</span></h1>
                            <img src={image} alt="" />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: descriptionRu
                                }} />

                            {videoUrl &&
                                <iframe width="100%" height="515" src={`https://www.youtube.com/embed/${videoUrl}`}
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen />
                            }

                            <p className="desc_news important_news data">
                                <i className="fa fa-calendar"></i>{date}
                            </p>
                        </div>
                        <LatestNews />
                    </div>
                </section>

            </section>


            {/* ****************************************************************** */}

            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
});

export default withRouter(compose(connect(mapStateToProps, {
}))(OneNewsDetails));
