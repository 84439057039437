import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import "./styles.scss";

function PastOrders(props) {

  const { totalPastOrderItems } = props

  const [totalOrders, setTotalOrders] = useState([])

  useEffect(
    () => {
      setTotalOrders(totalPastOrderItems)
    },
    [totalPastOrderItems]
  );

  const renderOrderTables = (tablesHours) => {
    const content = tablesHours.map(tableLine => {
      const { tableNumber, startHour, startHourMin, endHour, endHourMin } = tableLine
      return (
        <div className="table-line">
          <span>
            Стол <b>№{tableNumber}</b>
          </span>
          <span>
            с <b>{startHour}: {Number(startHourMin) === 0 ? `${startHourMin}0` : startHourMin}</b> до <b>{endHour}: {Number(endHourMin) === 0 ? `${endHourMin}0` : endHourMin}</b>
          </span>
        </div>
      )
    })

    return content

  }

  const renderTableOrders = (orders) => {
    const content = orders.map((orderLine, index) => {
      const { phoneNumber, reserveDate, userName, tablesHours } = orderLine
      return (
        <tr key={index}>
          <td>
            <b>{userName}</b>
          </td>
          <td>
            <b>{reserveDate}</b>
          </td>
          <td>
            <b>{phoneNumber}</b>
          </td>
          <td>
            {renderOrderTables(tablesHours)}
          </td>
        </tr>
      )
    })

    return content
  }


  return (
    <div>
      <h2>Таблица прошлых заказов</h2>
      <table>
        <thead>
          <th>Имя, фамилия</th>
          <th>Время заказа</th>
          <th>Телефон</th>
          <th>Столы</th>
        </thead>
        <tbody>
          {renderTableOrders(totalOrders)}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(PastOrders)
);
