import _ from 'lodash'
import React, { Fragment, useState } from 'react'
//import { Link } from 'react-router-dom'
import classnames from 'classnames'
import FlexBand, { FlexBandItem } from 'flexband'

import './styles.scss'

const defaultChunkSize = 10

export default props => {
  const { onClick = _.noop, className } = props
  const [, updateState] = useState()
  const forceUpdate = () => updateState({})
  window.onresize = _.debounce(forceUpdate, 300)

  const getPages = ({ total = 0, pageIndex = 0, pageSize = 20 }) => {
    let chunkSize = defaultChunkSize

    if (window.innerWidth < 704) chunkSize = 7
    if (window.innerWidth < 640) chunkSize = 6
    if (window.innerWidth < 600) chunkSize = 5
    if (window.innerWidth < 555) chunkSize = 4
    if (window.innerWidth < 514) chunkSize = 3
    const totalPages = Math.ceil(total / pageSize)
    const isFirstPage = total === 0 || pageIndex === 0
    const isLastPage = total === 0 || pageIndex === totalPages - 1

    // const [chunkSize = defaultChunkSize, setChunkSize] = useState()
    const totalChunks = Math.ceil(totalPages / chunkSize)
    const chunkIndex = Math.floor(pageIndex / chunkSize)
    const isFirstChunk = totalChunks === 0 || chunkIndex === 0
    const isLastChunk = totalChunks === 0 || chunkIndex === totalChunks - 1

    const pages = []
    const meta = []

    const getPageBlock = block => {
      if (block === 'prev') {
        return (chunkIndex - 1) * chunkSize
      } else {
        return (chunkIndex + 1) * chunkSize
      }
    }

    if (total > 0) {
      const item = (label, index, cssBase, disabled) => ({
        label,
        index,
        className: classnames(cssBase, { disabled }),
      })

      meta.push(item('<', 0, 'first', isFirstPage))
      meta.push(item('', isFirstPage ? -1 : getPageBlock('prev'), 'prev', isFirstChunk))
      for (let j = 0; j <= chunkSize; j++) {
        const index = j + chunkIndex * chunkSize
        if (index >= totalPages) break

        meta.push(
          item(index + 1, index, {
            'page-style': index !== pageIndex,
            current: index === pageIndex,
          })
        )
      }
      meta.push(item('', isLastPage ? -1 : getPageBlock('next'), 'next', isLastChunk))
      meta.push(item('>', totalPages - 1, 'last', isLastPage))

      meta.forEach((x, j) => {
        pages.push(
          <FlexBandItem key={j} className={classnames(x.className, 'pagination-item')}>
            <div
              onClick={event => {
                // stop other events
                event.preventDefault()
                // exit if item is disabled
                if (x.className.indexOf('disabled') !== -1) return
                // handle click
                x.index >= 0 && onClick(x.index)
                // scroll to the top
                //window.scrollTo(0, 0)
              }}
            >
              {x.label}
            </div>
          </FlexBandItem>
        )
      })
    }
    return pages
  }

  const pages = getPages(props)

  return (
    <Fragment>
      {pages.length > 0 && (
        <FlexBand
          className={classnames('full-width', 'ui-pagination', className)}
          justify="center"
          wrap="nowrap"
        >
          <FlexBand>{pages.map(x => x)}</FlexBand>
        </FlexBand>
      )}
    </Fragment>
  )
}
