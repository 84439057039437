import React from "react";
//import Spinner from "../../../helpers/spinner"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
//import classNames from 'classnames'
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import logo from "../../../images/logo.png"
import "./styles.scss"

function HeaderNavBar(props) {


  return (
    <section className="container box-logo">
      <header>
        <div className="content-logo col-md-12">
          <Link className="logo" to="/">
            <img src={logo} alt="" />
          </Link>

          <div className="bt-menu"><a href="/" className="menu"><span>&equiv;</span> Menu</a></div>

          <div className="box-menu">

            <nav id="cbp-hrmenu" className="cbp-hrmenu">
              <ul id="menu">
                <li>
                  <Link to="/rating">
                    Рейтинг
                  </Link>
                </li>
                <li>
                  <Link to="/tournaments">
                    Турниры
                  </Link>
                </li>
                <li>
                  <Link to="/players">
                    Игроки
                  </Link>
                </li>
                <li>
                  <Link to="/results">
                    Результаты
                  </Link>
                </li>
                <li>
                  <Link to="/news">
                    Новости
                  </Link>
                </li>
                <li>
                  <Link to="/shop">
                    Магазин
                  </Link>
                </li>
                <li>
                  <Link to="/training">
                    Тренировки
                  </Link>
                </li>
                <li>
                  <Link to="/contacts">
                    Контакты
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </section>
  );

}
//export default HeaderNavBar;

const mapStateToProps = (state) => ({
});

export default withRouter(compose(connect(mapStateToProps, {}))(HeaderNavBar));