import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import Header from '../../header'
import Footer from '../../footer'
import { connect } from "react-redux";
import Input from '../../../helpers/input'
//import { withNamespaces } from "react-i18next";
import axiosFetch from "../../../helpers/axios"
import { USER_REGISTRATION } from '../../../helpers/constants'
import Button from '../../../helpers/button'
import { isValidEmail } from "../../../helpers/constants/functions"
import { compose } from "redux";
import { withRouter, Redirect } from "react-router-dom";
import CookieHelper from "../../../helpers/cookie-helper";
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators"
import Spinner from "../../../helpers/spinner"

import "./styles.scss";

function RegistrationPage(props) {

  const { setLoginAuth } = props

  const [registrationData, setRegistrationData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [isRedirect, setIsRedirect] = useState(false)

  useEffect(() => {
    const userEmail = CookieHelper("get", "userEmail");
    if (userEmail) {
      setIsRedirect(true)
    }
  }, [])

  const setValue = (param, value) => {
    setError()
    const data = {
      ...registrationData,
      [param]: value
    }
    setRegistrationData(data)
  }

  const setErrorAndRemoveLoading = (errorValue) => {
    setError(errorValue)
    setIsLoading(false)
  }

  const registration = () => {
    setIsLoading(true)
    const { name, email, password, secondPassword } = registrationData
    const emailRegex = isValidEmail(email)
    if (!emailRegex) {
      setErrorAndRemoveLoading("Email некорректный")
    } else if (password !== secondPassword) {
      setErrorAndRemoveLoading("Пароли не совпадают")
    } else {
      setTimeout(() => {
        userReg(email, password, name)
      }, 500);
    }
  }

  const userReg = (email, password, name) => {

    const formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)
    formData.append("name", name)

    const response = axiosFetch(USER_REGISTRATION, formData)
    promiseResolved(response)
  }

  const promiseResolved = (response) => {
    Promise.resolve(response).then((resArr) => {

      if (resArr.error) {
        setError(resArr.error)
      } else {
        const { userInfo: { userName, userEmail, userPicture, userId } } = resArr

        CookieHelper("set", "userEmail", userEmail);
        CookieHelper("set", "userName", userName);

        const loginDataObj = {
          name: userName,
          email: userEmail,
          picture: userPicture,
          id: userId
        }

        setLoginAuth(loginDataObj)
        setIsRedirect(true)
      }
    })
    //setIsLoading(false)
  }

  const { name, email, password, secondPassword } = registrationData

  const renderContent = () => {
    return (



      <>
        <form method="post" className="register-form col-md-6">

          <div className="name">
            <label htmlFor="name">Имя, фамилия*</label><div className="clear"></div>
            {/* <input id="name" name="name" type="text" placeholder="e.g. Mr. John" required="" /> */}
            <Input
              type="text"
              id="name"
              value={name}
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("name", e.target.value)}
            />
          </div>
          <div className="email">
            <label htmlFor="email">Email*</label><div className="clear"></div>
            {/* <input id="email" name="email" type="text" placeholder="example@domain.com" required="" /> */}
            <Input
              type="text"
              id="email"
              value={email}
              placeholder="example@gmail.com"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("email", e.target.value)}
            />
          </div>

          <div className="name">
            <label htmlFor="password">Пароль*</label><div className="clear"></div>
            {/* <input id="password" name="password" type="password" placeholder="********" required="" /> */}
            <Input
              type="password"
              id="password"
              value={password}
              placeholder="*******"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("password", e.target.value)}
            />
          </div>
          <div className="confirm_password">
            <label htmlFor="secondPassword">Пароль ещё раз*</label><div className="clear"></div>
            {/* <input id="confirm_password" name="confirm_password" type="password" placeholder="********" required="" /> */}
            <Input
              type="password"
              id="secondPassword"
              value={secondPassword}
              placeholder="*******"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("secondPassword", e.target.value)}
            />
          </div>
          <div id="register-submit">
            <Button
              type="button"
              className="btn-upper btn btn-primary "
              text={"Зарегистрироваться"}
              disabled={!secondPassword || !password || !name || !email}
              onClick={() => registration()}
            />
          </div>
          {error && <div className="error-container">{error}</div>}
        </form>
        <div className="ctn-img reg-img col-md-6">
        </div>
        <div className="clear"></div>
      </>



    )

  }

  const content = isLoading ? <Spinner /> : renderContent()

  return (
    <div>
      <Header />
      <section id="login" className="container secondary-page">
        <div className="general general-results players">
          {isRedirect && <Redirect to="/" />}
          <div className="top-score-title right-score col-md-12">
            <h3>Зарегистрируйся <span>сейчас</span><span className="point-int"> !</span></h3>
            <div className="col-md-12 login-page">
              {content}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({});

export default withRouter(compose(connect(mapStateToProps, {
  setLoginAuth,
}))(RegistrationPage));
