import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { compose } from "redux";
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { getTotalOrders, toggleNotificationSound, getAllPastOrders } from "../../../../actions/GetTotalOrdersActionCreator"
import { GET_TOTAL_NEW_ORDERS } from '../../../../helpers/constants'
import axiosFetch from "../../../../helpers/axios"
import Spinner from "../../../../helpers/spinner";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import "./styles.scss";

function OptionsForAdmin(props) {



  const { startInterval, getTotalOrders, totalOrders, soundState, toggleNotificationSound, getAllPastOrders } = props
 
  const [volumeOnOff, setVolumeOnOff] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  useEffect(
    () => {
      setIsLoading(false)

      if (!startInterval) return;
      const interval = setInterval(_ => {

        const response = axiosFetch(GET_TOTAL_NEW_ORDERS)
        Promise.resolve(response).then((count) => {
          getTotalOrders(count, totalOrders, soundState)
        })

        getAllPastOrders()
      }, 3000);
      return _ => clearInterval(interval);
    },
    [startInterval, totalOrders, soundState, getTotalOrders, getAllPastOrders]
  );



  const toggleSound = () => {
    toggleNotificationSound(volumeOnOff)
    setVolumeOnOff(!volumeOnOff)
  }

  const renderNotiContent = () => {
    return (
      <>
        {sound}
        <Link to="/show-orders">({totalOrders})</Link>
        <Link to="/adminpanel"><SupervisorAccountIcon /></Link>
      </>
    )
  }

  const sound =
    !volumeOnOff ?
      <NotificationsOffIcon onClick={() => toggleSound()} className="notif-icon" /> :
      <NotificationsActiveIcon onClick={() => toggleSound()} className="notif-icon" />

  const content = !isLoading ? renderNotiContent() : <Spinner />

  return (
    <div className="notification-container">
      {content}
    </div>
  );
}

const mapStateToProps = (state) => ({
  totalOrders: state.GetTotalOrdersReducer.totalOrders,
  soundState: state.SoundNotificationReducer.soundState
});

export default withRouter(compose(connect(mapStateToProps, {
  getTotalOrders,
  toggleNotificationSound,
  getAllPastOrders
}))(OptionsForAdmin));
