import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import Header from '../../header'
import Footer from '../../footer'
import { connect } from "react-redux";
import Input from '../../../helpers/input'
//import { withNamespaces } from "react-i18next";
import axiosFetch from "../../../helpers/axios"
import { USER_LOGIN } from '../../../helpers/constants'
import Button from '../../../helpers/button'
import { isValidEmail } from "../../../helpers/constants/functions"
import { compose } from "redux";
import { withRouter, Redirect } from "react-router-dom";
import CookieHelper from "../../../helpers/cookie-helper";
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators"
import Spinner from "../../../helpers/spinner"

import "./styles.scss";

function LoginPage(props) {

  const { setLoginAuth } = props
  const lng = "ru"
  const [loginData, setLoginData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [isRedirect, setIsRedirect] = useState(false)

  useEffect(() => {
    const userEmail = CookieHelper("get", "userEmail");
    if (userEmail) {
      setIsRedirect(true)
    }
  }, [])

  const setValue = (param, value) => {
    setError()
    const data = {
      ...loginData,
      [param]: value
    }
    setLoginData(data)
  }

  const enter = () => {
    setIsLoading(true)
    const { email, password } = loginData
    const emailisValid = isValidEmail(email)
    if (!emailisValid) {
      setError("Email некорректный")
      setIsLoading(false)
    } else {

      setTimeout(() => {
        userCheckAuth(email, password)
      }, 500);
    }
  }

  const userCheckAuth = (email, password) => {

    const formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)

    const response = axiosFetch(USER_LOGIN, formData)
    
    promiseResolved(response)

  }

  const promiseResolved = (response) => {
    Promise.resolve(response).then((resArr) => {
      
      if (resArr.errorRu) {
        const finalTitle = lng === "ru" ? resArr.errorRu : resArr.errorRo
        setError(finalTitle)
      } else {
        const { userInfo: { userName, userEmail, userPicture, userId, userPhone, userRole } } = resArr

        CookieHelper("set", "userEmail", userEmail);
        CookieHelper("set", "userName", userName);
        CookieHelper("set", "userPhone", userPhone);
        CookieHelper("set", "userId", userId || 0);
        CookieHelper("set", "userRole", userRole || "user");

        const loginDataObj = {
          name: userName,
          email: userEmail,
          picture: userPicture,
          phone: userPhone,
          id: userId
        }

        setLoginAuth(loginDataObj)
        setIsRedirect(true)
      }
    })
    setIsLoading(false)
  }

  const { email, password } = loginData

  const renderContent = () => {
    return (
      <>
        <form method="post" className="register-form reg-img col-md-6">
          <div className="email">
            <label htmlFor="email">Email*</label><div className="clear"></div>
            <Input
              type="text"
              id="email"
              value={email}
              placeholder="example@gmail.com"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("email", e.target.value)}
            />
          </div>

          <div className="name">
            <label htmlFor="password">Пароль*</label><div className="clear"></div>
            <Input
              type="password"
              id="password"
              value={password}
              placeholder="*******"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("password", e.target.value)}
            />
          </div>
          <div id="register-submit">
            <Button
              type="button"
              className="btn-upper btn btn-primary "
              text={"Войти"}
              disabled={!password || !email}
              onClick={() => enter()}
            />
          </div>
          {error && <div className="error-container">{error}</div>}
        </form>

        <div className="clear"></div>
      </>
    )

  }

  const content = isLoading ? <Spinner /> : renderContent()

  return (
    <div>
      <Header />
      <section id="login" className="container secondary-page">
        <div className="general general-results players">
          {isRedirect && <Redirect to="/" />}
          <div className="top-score-title right-score col-md-12">
            <h3>Вход в личный кабинет</h3>
            <div className="col-md-12 login-page reg-page ">
              {content}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({});

export default withRouter(compose(connect(mapStateToProps, {
  setLoginAuth,
}))(LoginPage));
