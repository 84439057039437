import React from "react";
//import { Link } from "react-router-dom";
import Header from '../../header'
import Footer from '../../footer'
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import "./styles.scss";

function Training(props) {


  return (
    <div>
      <Header />
      <div className="container secondary-page">
        <div className="general">
          <div className="col-md-12">
          Training
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(Training)
);
