import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { withRouter } from "react-router-dom";
//import axiosFetch from "../../helpers/axios";
import CookieHelper from "../../helpers/cookie-helper";
import { setToken } from "../../helpers/constants/functions"
import HomePage from '../pages/homepage';
import LoginPage from '../pages/login-page';
import RegistrationPage from '../pages/registration-page';
import RatingPage from '../pages/rating'; 
import TournamentsPage from '../pages/tournaments'; 
import PlayersPage from '../pages/players'; 
import ReservePage from '../pages/reserve'; 
import ResultsPage from '../pages/results'; 
import Adminpanel from '../pages/adminpanel'; 
import NewsPage from '../pages/news'; 
import ShowOrders from '../pages/show-orders'; 
import ShopPage from '../pages/shop'; 
import TrainingsPage from '../pages/training'; 
import MyCabinet from '../pages/my-cabinet'; 
import OneNewsDetails from '../pages/one-news-details';  
import OneEventsDetails from '../pages/one-events-details';  
import ContactsPage from '../pages/contacts'; 
import Cookies from 'universal-cookie'
import { compose } from "redux";
import { connect } from "react-redux";
import { setLoginAuth } from "../../actions/SetLoginAuthActionCreators"

import "./styles.scss"

function App(props) {

    const [approveCookies, setApproveCookies] = useState(false)

    useEffect(() => {
        const token = CookieHelper("get", "token");
        const generateToken = setToken()

        if (!token) {
            CookieHelper("set", "token", generateToken);
        }

        const cookies = new Cookies()
        const cookieData = cookies.get('approveCookies')
        setApproveCookies(cookieData)

    },[setApproveCookies]);


    const setCookie = () => {
        const cookies = new Cookies()
        cookies.set('approveCookies', true)
        setApproveCookies(true)
    }

    const renderCookieModal = () => {
        //const { t } = this.props
        return (
            <div className="coockie-modal-contaner">
                <div className="coockie-content-container">
                    {/* <h3>{t('Cookie_permission')}</h3> */}
                    <h2>Разрешение на использование cookie</h2>
                    <button
                        type="button"
                        className="btn btn-primary icon"
                        onClick={() => setCookie()}
                    >
                        OK
                        </button>
                    <div className="coockie-modal-note">
                        {/* {t('Cookie_note')} */}
                        Мы используем файлы cookie для распознавания ваших 
                        повторных посещений и предпочтений, а также для анализа трафика. 
                        Чтобы узнать больше о файлах cookie, ознакомьтесь с нашей Политикой конфиденциальности. 
                        Нажав «Принять» или используя наш сайт, вы даете согласие на использование файлов cookie, 
                        если вы не отключили их. Отключая их, вы не можете использовать определенные части сайта
                    </div>
                </div>
            </div>
        )
    }
   
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/login" exact component={LoginPage} />
                <Route path="/register" exact component={RegistrationPage} />

                <Route path="/rating" exact component={RatingPage} />
                <Route path="/tournaments" exact component={TournamentsPage} />
                <Route path="/players" exact component={PlayersPage} />
                <Route path="/results" exact component={ResultsPage} />
                <Route path="/news" exact component={NewsPage} />
                <Route path="/shop" exact component={ShopPage} />
                <Route path="/training" exact component={TrainingsPage} />
                <Route path="/contacts" exact component={ContactsPage} />
                <Route path="/reserve" exact component={ReservePage} />

                <Route path="/cabinet" exact component={MyCabinet} />
                <Route path="/show-orders" exact component={ShowOrders} />
                <Route path="/adminpanel" exact component={Adminpanel} />
                <Route path="/news/:id" exact component={OneNewsDetails} />
                <Route path="/events/:id" exact component={OneEventsDetails} />
                <Redirect to="/" />

            </Switch>
            {!approveCookies && renderCookieModal()}
        </Router>
    )

}

const mapStateToProps = (state) => ({
});

export default withRouter(compose(connect(mapStateToProps, {
    setLoginAuth,
}))(App));

