import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Button from '../../../../../helpers/button'
import Input from "../../../../../helpers/input";
import { ADD_NEWS_ITEM, EDIT_NEWS_ITEM } from "../../../../../helpers/constants";
import axiosFetch from "../../../../../helpers/axios";
import Spinner from "../../../../../helpers/spinner";
import classNames from "classnames"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import "./styles.scss";

function AddEditOneItem(props) {
  const { goBack, editItemData } = props
  const emptyObj = {
    titleRu: "",
    titleRo: "",
    image: null,
    descriptionRu: "",
    descriptionRo: "",
    videoUrl: "",
  }
  const [formData, setFormData] = useState(emptyObj)
  const [photoPreview, setPhotoPreview] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [valueTestRu, setValueTestRu] = useState('');
  const [valueTestRo, setValueTestRo] = useState('');



  useEffect(() => {
    const emptyObj = {
      titleRu: "",
      titleRo: "",
      image: null,
      descriptionRu: "",
      descriptionRo: "",
      videoUrl: "",
    }
    
    const obj = editItemData ? editItemData : emptyObj

    if (editItemData?.id) {
      setValueTestRu(editItemData.descriptionRu)
      setValueTestRo(editItemData.descriptionRo)
    }

    setFormData(obj)
  }, [editItemData])


  const setValue = (param, value) => {
    const data = {
      ...formData,
      [param]: value
    }
    setFormData(data)
  }

  const handleChangePhoto = (file) => {
    const data = {
      ...formData,
      image: file
    }
    setFormData(data)
    setPhotoPreview(URL.createObjectURL(file))
  }



  const addItem = () => {
    const { titleRu, titleRo, image, videoUrl } = formData

    setIsLoading(true);


    const fd = new FormData()
    fd.append("titleRu", titleRu)
    fd.append("titleRo", titleRo)
    fd.append("descriptionRu", valueTestRu)
    fd.append("descriptionRo", valueTestRo)
    fd.append("image", image)
    fd.append("videoUrl", videoUrl)

    axiosFetch(ADD_NEWS_ITEM, fd).then(() => {
      setTimeout(() => {
        setIsLoading(false)
        goBack()
      }, 500);
    })
  }

  const editItem = () => {
    const { titleRu, titleRo, image, id, videoUrl } = formData
    setIsLoading(true);


    const fd = new FormData()
    fd.append("id", id)
    fd.append("titleRu", titleRu)
    fd.append("titleRo", titleRo)
    fd.append("descriptionRu", valueTestRu)
    fd.append("descriptionRo", valueTestRo)
    fd.append("videoUrl", videoUrl)
    if (photoPreview) {
      fd.append("image", image)
    }


    axiosFetch(EDIT_NEWS_ITEM, fd).then(() => {
      setTimeout(() => {
        setIsLoading(false)
        goBack()
      }, 500);
    })
  }

  const { titleRu, titleRo, image, videoUrl } = formData
  const disabled = !titleRu || !titleRo || !valueTestRu || !valueTestRo || !image
  const renderForm = () => {
    return (
      <form>
        <div className="name">
          <label htmlFor="titleRu">Заголовок на русском (RU):</label>
          <div className="clear"></div>
          <Input
            value={titleRu}
            className={"form-control"}
            type={"text"}
            id={"titleRu"}
            placeholder={"Заголовок на русском"}
            onChange={(e) => setValue("titleRu", e.target.value)}
          />
        </div>
        <div className="email">
          <label htmlFor="titleRo">Заголовок на румынском (RO):</label>
          <div className="clear"></div>
          <Input
            value={titleRo}
            className={"form-control"}
            type={"text"}
            id={"titleRo"}
            placeholder={"Заголовок на румынском"}
            onChange={(e) => setValue("titleRo", e.target.value)}
          />
        </div>
        <div className="message">
          <label htmlFor="photos">Фотография:</label>

          <div className="clear"></div>
          {photoPreview ? (<div
            className="img-container"
            style={{
              backgroundImage: `url(${photoPreview})`,
            }}
          />) : image !== null ? (<div
            className="img-container"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />) : ""}
          <Input
            colMd={""}
            type={"file"}
            placeholder={"Картинка новости"}
            name={"avatar"}
            onChange={(e) => handleChangePhoto(e.target.files[0])}
          />
        </div>
        <div className="message">
          <label htmlFor="itemDescriptionRu">Текст новости (Ru):</label>
          <ReactQuill
            theme="snow"
            value={valueTestRu}
            onChange={setValueTestRu}
          />
        </div>

        <div className="message">
          <label htmlFor="itemDescriptionRo">Текст новости (Ro):</label>

          <ReactQuill
            theme="snow"
            value={valueTestRo}
            onChange={setValueTestRo}
          />
        </div>

        <div className="message">
          <label htmlFor="videoUrl">Id Youtube video:</label>

          <Input
            value={videoUrl}
            className={"form-control"}
            type={"text"}
            id={"videoUrl"}
            placeholder={"Url Youtube video"}
            onChange={(e) => setValue("videoUrl", e.target.value)}
          />
        </div>


        <div className="add-item-button-container">
          <Button
            type="button"
            className={classNames({
              "add-item-button": true,
              "disable-button": disabled
            })}
            text={editItemData ? "Редактировать" : "Добавить новость"}
            onClick={() => editItemData ? editItem() : addItem()}
          />

        </div>
      </form>
    )
  }

  const content = isLoading ? <Spinner /> : renderForm();

  return (
    <div>

      <div className="add-news-form">
        <div className="top-score-title col-md-12 align-center">
          {content}
        </div>
      </div>
    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(AddEditOneItem)
);
