import React, { Component } from 'react';

export default class Input extends Component {  

    render(){
        const { 
            className='form-control', 
            type, 
            required = false, 
            placeholder, 
            onChange, 
            name = 'input', 
            value, 
            disabled = false,
            onKeyDown,
            colMd = "",
            maxLength
            //id=""
        } = this.props;

        return(
            <div className={colMd.length > 0 ? colMd : ''}>
                <input className={className} 
                       type={type}
                       name={name} 
                       maxLength={maxLength}
                       required={required}
                       placeholder={placeholder} 
                       onChange={onChange}
                       onKeyDown={onKeyDown}
                       value={value}
                       //id={id}
                       disabled={disabled}/>
            </div>
        );
    };
}