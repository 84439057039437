import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { DELETE_EVENTS_ITEM, GET_ALL_EVENTS, ITEMS_ON_THE_ADMIN_PAGE } from "../../../../../helpers/constants";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../../../../../helpers/button'
import axiosFetch from "../../../../../helpers/axios";
import Pagination from '../../../../../helpers/pagination'

import "./styles.scss";

function NewsTable(props) {
  const { goBack, setEditEventsItem } = props
  const [deleteId, setDeleteId] = useState(0)
  const [eventsList, setEventsList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  //const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchEventsData()
  }, [])

  const setEditId = (item) => {
    setEditEventsItem(item)
    goBack("editItem")
  }

  const fetchEventsData = (page) => {
    const formData = new FormData()
    formData.append("currentPage", page)
    formData.append("itemsOnPage", ITEMS_ON_THE_ADMIN_PAGE)

    const response = axiosFetch(GET_ALL_EVENTS, formData);

    Promise.resolve(response).then((resArr) => {
      setTotalCount(resArr[0]?.total || 0)
      setEventsList(resArr);
      //setIsLoading(false);
    });
  }

  const paginationClick = page => {
    //setIsLoading(true)
    setCurrentPage(page)
    fetchEventsData(page)
  }

  const renderEvents = () => {
    const content = eventsList.map((item, index) => {
      const { id, image, titleRu, date } = item

      return (
        <tr className="match-sets" key={index}>

          <td className="cell-data" >
            <img src={image} style={{ maxHeight: 40 }} alt={'titleRu'} onClick={() => setEditId(item)} />
          </td>
          <td colSpan={4} className="t-align-left">
            <p onClick={() => setEditId(item)}>{titleRu}</p></td>
          <td className="cell-data">{date}</td>
          <td className="cell-actions">
            <EditIcon className="action-edit" onClick={() => setEditId(item)} />
            <DeleteIcon className="action-delete" onClick={() => setDeleteId(id)} />
          </td>
        </tr>

      )
    })

    return content
  }

  const deleteEventsItem = () => {
    const fd = new FormData()
    fd.append("id", deleteId)
    axiosFetch(DELETE_EVENTS_ITEM, fd).then(() => {
      setDeleteId(0)
      fetchEventsData()
    })
  }

  const renderDeleteContent = () => {
    const deleteItem = eventsList.find(item => item.id === deleteId)

    return (
      <div className="delete-overlay">
        <h2>Удаляем мероприятие?</h2>
        "{deleteItem.titleRu}"

        <div className="delete-item-button-container">
          <Button
            type="button"
            className="btn-upper btn btn-primary reserve"
            text={"Удалить"}
            onClick={() => deleteEventsItem()}
          />
          <Button
            type="button"
            className="btn-upper btn btn-primary reserve"
            text={"Отмена"}
            onClick={() => setDeleteId(0)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="news-table-container">
      {deleteId > 0 && renderDeleteContent()}
      <table className="match-tbs">
        <tbody>
          {renderEvents()}

          <tr>
            <td className="match-more" colSpan="7">

              {totalCount > ITEMS_ON_THE_ADMIN_PAGE && <Pagination
                onClick={paginationClick}
                total={totalCount}
                pageIndex={currentPage}
                pageSize={ITEMS_ON_THE_ADMIN_PAGE}
              />}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  );
}

const mapStateToProps = (state) => ({
});

export default withRouter(
  compose(connect(mapStateToProps, {}),)(NewsTable)
);
