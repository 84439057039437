export const APP_TITLE = "TopCup";
export const SITE_URL = "http://localhost:3000/";
export const API_URL = 'https://backend-topcup.ssv-design.com/Main';
export const BASE_URL = 'https://backend-topcup.ssv-design.com';

export const NO_IMAGE_URL = BASE_URL + '/uploads/no-image.png';
export const ITEMS_ON_THE_ADMIN_PAGE = 10
export const ITEMS_ON_THE_PAGE = 4
export const WIDGET_NEWS_LIMIT_ITEMS = 3
export const WIDGET_EVENTS_LIMIT_ITEMS = 3
export const USER_FB_LOGIN = API_URL + "/userFbLogin" //Авторизация через FB
export const USER_REGISTRATION = API_URL + "/userRegistration" //Регистрация юзера
export const USER_LOGIN = API_URL + "/userLogin" //Авторизация через форму входа
export const GET_USER_INFO_BY_EMAIL = API_URL + "/getUserInfoByEmail" //Получение информации о пользователе по емайлу

export const EDIT_USER_PROFILE = API_URL + "/editUserProfile" //Редактирование анкеты пользователя

export const SET_ORDER_TO_RESERVE = API_URL + "/setOrderToReserve" // Создание заказа

export const SET_TABLES_INTO_ORDER = API_URL + "/setTablesIntoOrder" // Создание заказа

export const GET_TOTAL_NEW_ORDERS = API_URL + "/getTotalNewOrders" // Получение кол-ва новых заказов

export const GET_TOTAL_PAST_ORDERS = API_URL + "/getTotalPastOrders" // Получение кол-ва новых заказов

export const GET_ALL_ORDERS = API_URL + "/getAllOrders" // Получение кол-ва новых заказов

export const GET_NEW_ORDERS = API_URL + "/getNewOrders" // Получение кол-ва новых заказов 

export const SET_ORDER_APPROVE = API_URL + "/setOrderApprove" // Одобрение бронирования

export const SET_ORDER_DECLINE = API_URL + "/setOrderDecline" // Отклонение бронирования

export const GET_ALL_TABLES = API_URL + "/getAllTables" // Получение всех активных столов

export const ADD_NEW_TABLE = API_URL + "/addNewTable" // Добавление нового стола

export const CHANGE_TABLE_ACTIVITY = API_URL + "/changeTableActivity" // Смена активности стола (online )

export const GET_HOURS  = API_URL + "/getHours" // Получение часов работы 

export const SET_HOURS = API_URL + "/setHours" // Смена часов работы 

export const CHECK_AVAILABLE_HOURS = API_URL + "/checkAvailableHours" // Смена часов работы 

export const ADD_NEWS_ITEM = API_URL + "/addNewsItem" // Добавление новости
export const EDIT_NEWS_ITEM = API_URL + "/editNewsItem" // Редактирование новости
export const GET_ALL_NEWS = API_URL + "/getAllNews" // Получение всех новостей
export const DELETE_NEWS_ITEM = API_URL + "/deleteNewsItem" // Удаление одной новости
export const GET_ONE_NEWS_DETAILS = API_URL + "/getOneNewsDetails" // Удаление одной новости 
export const LATETS_NEWS = API_URL + "/latestNews" // Последние новости  



export const ADD_EVENTS_ITEM = API_URL + "/addEventsItem" // Добавление мероприятия
export const EDIT_EVENTS_ITEM = API_URL + "/editEventsItem" // Редактирование мероприятия
export const GET_ALL_EVENTS = API_URL + "/getAllEvents" // Получение всех мероприятий
export const DELETE_EVENTS_ITEM = API_URL + "/deleteEventsItem" // Удаление одного мероприятия
export const GET_ONE_EVENTS_DETAILS = API_URL + "/getOneEventsDetails" // Удаление одного мероприятия 
export const LATETS_EVENTS = API_URL + "/latestEvents" // Последние мероприятия