import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import NewsList from "./news-list"

function LastTournaments() {


    return (
        <section className="news_slide-over-color">
            <div className="news_slide-over"></div>
            <div className="container">
            <h1 style={{textAlign: "center"}}>Новости</h1>
                <NewsList />
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
});

export default withRouter(compose(connect(mapStateToProps, {
}))(LastTournaments));
