import {
    GET_TOTAL_ORDERS,
    GET_ALL_ACTIVE_ORDERS,
    GET_ALL_NOT_ACTIVE_ORDERS
} from "../actions/Actions";

const initialState = {
    totalOrders: 0,
    totalActiveOrdersItems: [],
    totalPastOrderItems: [],
};

let notifyAudio = new Audio(`${process.env.PUBLIC_URL}/notification.wav`)

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TOTAL_ORDERS:

            const { counter, soundState } = action.payload
            //notifyAudio.play()
            if (soundState) { notifyAudio.play() }

            return Object.assign({}, {
                ...state,
                totalOrders: counter,
            });


        case GET_ALL_ACTIVE_ORDERS:

            return Object.assign({}, {
                ...state,
                totalActiveOrdersItems: action.payload,
            });

        case GET_ALL_NOT_ACTIVE_ORDERS:

            return Object.assign({}, {
                ...state,
                totalPastOrderItems: action.payload,
            });
            
        default:
            return state;
    }
}
