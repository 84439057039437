import {combineReducers} from "redux";
import SetLoginReducer from "./SetLoginReducer";
import GetTotalOrdersReducer from "./GetTotalOrdersReducer"
import SoundNotificationReducer from "./SoundNotificationReducer"

const rootReducer = combineReducers({
    SetLoginReducer,
    GetTotalOrdersReducer,
    SoundNotificationReducer
});

export default rootReducer;
