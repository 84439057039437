import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
//import { withNamespaces } from "react-i18next";
import { compose } from "redux";



function GetStartedToday() {


    return (
        <section id="parallaxTraining">
            <div className="black-shad">
                <div className="container">
                    <div className="col-md-12">
                        <div className="txt-training">
                            <p>Начни свою</p>
                            <h2>тренировку сегодня</h2>
                            <Link to="/reserve">Забронировать стол</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
});

export default withRouter(compose(connect(mapStateToProps, {
}))(GetStartedToday));
