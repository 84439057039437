import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Cart from "./cart";
//import { USER_FB_LOGIN } from "../../../helpers/constants";
//import axiosFetch from "../../../helpers/axios";
import CookieHelper from "../../../helpers/cookie-helper";
//import FacebookLogin from "react-facebook-login";
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators";
//import Spinner from "../../../helpers/spinner";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import OptionsForAdmin from "./options-for-admin"
//import { withNamespaces } from "react-i18next";

import "./styles.scss";

function HeaderTopBar(props) {
  const { setLoginAuth, loginData } = props;
  //const [isLoading, setIsLoading] = useState(false);
  const [startInterval, setStartInterval] = useState(true)
  useEffect(() => {
    const userName = CookieHelper("get", "userName");
    const userEmail = CookieHelper("get", "userEmail");
    const userPhone = CookieHelper("get", "userPhone");
    const userId = CookieHelper("get", "userId");
    const userRole = CookieHelper("get", "userRole");

    const setToken = () => {
      var token = "";
      var possible =
        "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

      for (var i = 0; i < 50; i++)
        token += possible.charAt(Math.floor(Math.random() * possible.length));

      return token;
    };

    const token = CookieHelper("get", "token");
    const generateToken = setToken();

    if (!token) {
      CookieHelper("set", "token", generateToken);
    }

    const loginDataObj = {
      name: userName,
      email: userEmail,
      phone: userPhone,
      userId,
      userRole,
    };

    setLoginAuth(loginDataObj);
    //
  }, [setLoginAuth]);

  const isAdmin = loginData.userRole === "admin"

  // const componentClicked = () => {
  //   setIsLoading(true);
  // };

  // const responseFacebook = (response) => {
  //   setIsLoading(true);
  //   if (!response.status) {
  //     const {
  //       email,
  //       name,
  //       id,
  //       picture: {
  //         data: { url },
  //       },
  //     } = response;

  //     CookieHelper("set", "userEmail", email);
  //     CookieHelper("set", "userName", name);

  //     const formData = new FormData();
  //     formData.append("email", email);
  //     formData.append("name", name);
  //     formData.append("fbId", id);
  //     formData.append("picture", url);

  //     axiosFetch(USER_FB_LOGIN, formData);

  //     const loginDataObj = {
  //       name,
  //       email,
  //       picture: url,
  //     };

  //     setLoginAuth(loginDataObj);
  //     //setIsRedirect(true)
  //   }
  //   setIsLoading(false);
  // };

  const exit = () => {
    setStartInterval(false)

    CookieHelper("remove", "userEmail");
    CookieHelper("remove", "userName");
    CookieHelper("remove", "userPhone");
    CookieHelper("remove", "userId");
    CookieHelper("remove", "userRole");
    const data = {};
    setLoginAuth(data);
    //setLoginData(data)
  };

  const notAutorizedContainer = () => {
    return (
      <>
        {/* <FacebookLogin    temporary disabled
          appId="589095468736562"
          autoLoad={false}
          fields="name,email,picture"
          onClick={componentClicked}
          callback={responseFacebook}
          cssClass="auth-with-facebook"
          textButton={"Войти"}
        /> */}

        <Link to="/login">Авторизация</Link>
        <Link to="/register">Зарегистрироваться</Link>
      </>
    );
  };

  const autorizedContainer = () => {

    return (
      <>
        <Link to="/cabinet">
          <AccountCircleIcon className="account-image" />
          {loginData.name}
        </Link>
        {isAdmin && <OptionsForAdmin {...{ startInterval }} />}

        <b className="exit-btn" onClick={() => exit()}>
          {" "}
          (Выход){" "}
        </b>
      </>
    );
  };

  const onLoadContainer = () => {
    return (
      <div className="auth-container">
        <div className="auth-links-container">
          {loginData && loginData.name
            ? autorizedContainer()
            : notAutorizedContainer()}
        </div>
        {/* <i className="fa fa-shopping-cart"></i> */}
      </div>
    );
  };

  return (
    <section className="content-top-login">
      <div className="container">
        <div className="col-md-12">
          <div className="box-support">
            <p className="support-info">
              <i className="fa fa-envelope-o"></i> support@topcup.md
            </p>
          </div>
          <div className="box-login">
            {/* {isLoading ? <Spinner /> : onLoadContainer()} */}
            {onLoadContainer()}
          </div>
          <div className="cart-prod hiddenbox">
            <Cart />
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  loginData: state.SetLoginReducer.loginData,
});

export default withRouter(
  compose(
    connect(mapStateToProps, {
      setLoginAuth,
    })
  )(HeaderTopBar)
);
